<template>
    <section id="hero">
        <base-jumbotron
                :src="src"
        >
            <v-container
                    fill-height
            >
                <v-layout
                        align-center
                        justify-center
                >
                        <base-title
                                :header="title"
                        >
                        </base-title>
                </v-layout>
            </v-container>
        </base-jumbotron>
    </section>
</template>

<script>
    export default {
        props:{
          src:{
              type: String,
              default: './images/p1.jpg'
          }
        },
        name:'Hero',
        data: () => ({
            title: ''

        })
    }
</script>
